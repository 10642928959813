import styled from 'styled-components';

export default styled.li`
  display: flex;
  width: 100%;
  flex-direction: column;
  & > :not(:last-child) {
    font-style: italic;
    font-size: 80%;
  }
  & > :last-child {
    align-self: flex-end;
  }
`;