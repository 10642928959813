import React from 'react'
import styled from 'styled-components'

const Vid = styled.video`
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translate(-32px);
  z-index: -1;
  background: #000;
  opacity: ${props => props.faded ? 0.6 : 1};
`;

export default props => <Vid {...props} autoPlay loop muted />
