import React from 'react'
import styled from 'styled-components'

import A4C from './logo/a4c.png'
import Capgemini from './logo/capgemini.png'
import Aston from './logo/aston.jpg'

const LogoContainer = styled.div`
  display: flex;
  position: fixed;
  bottom: 0;
  right: 0;
  border-top-left-radius: 30px;
  background: white;
  & > * {
    display: block;
    margin: 1em;
    height: 40px;
  }
`;

export default () => <LogoContainer>
  <img alt="Action for Children" src={A4C} />
  <img alt="Capgemini" src={Capgemini} />
  <img alt="Aston University" src={Aston} />
</LogoContainer>
