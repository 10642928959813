import React, { useState } from 'react';

import {
  Box,
  Deck,
  FlexBox,
  Slide,
  Appear
} from 'spectacle';

import styled from 'styled-components'

import VideoBackdrop from '../components/VideoBackdrop'
import TitleSlide from '../components/TitleSlide'
import SlideLogos from '../components/SlideLogos'
import SlideTemplate from '../components/SlideTemplate'
import Quote from '../components/Quote'
import QuoteSlide from '../components/QuoteSlide'

const template = () => (
  <FlexBox
    justifyContent="space-between"
    position="absolute"
    bottom={0}
    width={1}
    style={{
      zIndex: 10
    }}
  >
    <Box padding="0 1em">
    </Box>
    <SlideLogos />
  </FlexBox>
);

const Frame = styled.iframe`
  transform: scale(0.35);
  transform-origin: 50% 0%;
  margin: 0 auto;
  transition: opacity 1s ease-in-out;
  opacity: 1;
  ${props => props.perspective ? `
    transform: matrix3d(13.6209,-2.70954,0,-0.00761543,2.27587,32.5648,0,0.00632519,0,0,1,0,251,154,0,1) scale(0.0078) scaleX(1.87) translate(-5.7%,-8.5%) rotate(0.9deg) rotateY(-12deg);
    transform-origin: 0px 0px;
    border: 0;
  ` : ""}
`;

const YouTubeEmbed = styled.iframe`
  border: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const ElfFrame = (props) => {
  const [loaded, setLoaded] = useState(false)
  return <Frame {...props} loaded={loaded} onLoad={() => setLoaded(true)} />
}

const Presentation = () => (
  <Deck template={template} transitionEffect="fade">
    <Slide
      backgroundColor="black"
    >
      <VideoBackdrop 
        faded
        src="https://firebasestorage.googleapis.com/v0/b/a4c-ai-gifting.appspot.com/o/video%2Fmain-loop.webm?alt=media"
       />
       <TitleSlide title="Elf.ai" subtitle="Applying AI with Action for Children" />
    </Slide>
    <Slide>
      <SlideTemplate 
        black
        colour="#23D9B7"
        title="An introduction" 
        subtitle="background" 
        image={require("../assets/profile.jpg")}>
        <ul>
          <Appear elementNum={0}>
            <li>Emerging Technology Engineer</li>
          </Appear>
          <Appear elementNum={1}>
            <li>I work in Capgemini's Applied Innovation Exchange (AIE)</li>
          </Appear>
          <Appear elementNum={2}>
            <li>Working to solve real-world problems with emerging technologies such as AI, Blockchain, IoT with our clients.</li>
          </Appear>
          {/* <Appear elementNum={3}>
            <li>I have a passion for using technology as a means of solving problems and describe myself as an all-round techie.</li>
          </Appear> */}
        </ul>
      </SlideTemplate>
    </Slide>
    <Slide>
      <SlideTemplate 
        colour="#0583F2"
        title="Business Context"
        subtitle="background"
        image={require("../assets/a4c-residency.jpeg")}>
        <ul>
          <Appear elementNum={0}>
            <li>Action for Children aim is to protect and support children and young people to ensure they have "Safe and Happy childhoods".</li>
          </Appear>
          <Appear elementNum={1}>
            <li>Action for Children's digital team joined us in the AIE for a 5-week residency.</li>
          </Appear>
          <Appear elementNum={2}>
            <li>Post-residency, in late October, discussions began about an opportunity to partner on a project for their first Christmas pop-up store. To deliver an emerging technology installation for the store and in the process improve the number of donations whilst giving visibility for Capgemini.</li>
          </Appear>
          <Appear elementNum={3}>
            <li>Given the short notice nature of the project, funding questions and engineering demand from clients, I opted to undertake the project as an individual project to be delivered outside of work hours.</li>
          </Appear>
        </ul>
      </SlideTemplate>
    </Slide>
    <Slide>
      <SlideTemplate 
        colour="#5207F2"
        title="The Goals"
        subtitle="background"
        image={require("../assets/store.jpg")}>
        <ul>
          <Appear elementNum={0}>
            <li>Action for Children's store aimed to collect donations for the vulnerable children they support. Crucially, the store would open on December 4th, giving a timeline of <b>5 weeks.</b></li>
          </Appear>
          <Appear elementNum={1}>
            <li>Our collaborative effort should aim to <b>increase donations</b> or <b>improve visibility</b> of the store.</li>
          </Appear>
          <Appear elementNum={2}>
            <li>From a Capgemini perspective, the project would aim to not only serve as an <b>exposure opportunity</b> but also offer a <b>case-study</b> for the retail application of emerging technologies.</li>
          </Appear>
          <Appear elementNum={3}>
            <li>Individually, I aimed to deliver a <b>meaningful</b> and <b>challenging</b> individual project that was more than a tick-box exercise. The project should offer an opportunity to both <b>learn and document</b> those learnings for future Capgemini delivery.</li>
          </Appear>
        </ul>
      </SlideTemplate>
    </Slide>
    <Slide>
      <SlideTemplate 
        colour="#0583F2"
        title="The idea"
        subtitle="background"
        image={require("../assets/gifts.jpg")}>
        <ul>
          <Appear elementNum={0}>
            <li>We started with a collaborative workshop where we could understand the store and then expand on the ideas we'd initially proposed.</li>
          </Appear>
          <Appear elementNum={1}>
            <li>The store would follow the messaging and theme of their "Secret Santa" campaign. Guests choose gift-tags representing a gift they would like to donate to a vulnerable child as their Secret Santa.</li>
          </Appear>
          <Appear elementNum={2}>
            <li>After discussing ideas from Augmented Reality art to Virtual Reality gamification of the gifting experience, we near unanimously decided on the idea for a concept we called <b>Elf.ai</b></li>
          </Appear>
          <Appear elementNum={3}>
            <li>Elf.ai would help visitors to the store decide which gift they should donate to a vulnerable child. By exposing the guest to festive imagery, the emotional reaction to each image would be used to make a gift prediction.</li>
          </Appear>
          <Appear elementNum={4}>
            <li>Before leaving, the visitor is able to unlock an exclusive social media filter which they can share, <b>promoting the store.</b>.</li>
          </Appear>
        </ul>
      </SlideTemplate>
    </Slide>
    <Slide>
      <SlideTemplate 
        colour="#5207F2"
        title="Research"
        subtitle="background"
        image={require("../assets/research.jpg")}>
        <ul>
          <Appear elementNum={0}>
            <li>The most critical part of the system would be a <b>computer vision</b> model for detecting <b>emotional expression</b> in almost real-time.</li>
          </Appear>
          <Appear elementNum={1}>
            <li>Initial research uncovered <b>cloud products</b> that offer this functionality such as AWS Rekognition, Microsoft Face API, Google Cloud Vision. But <b>cost</b> and <b>data protection</b> were a concern.</li>
          </Appear>
          <Appear elementNum={2}>
            <li>Academic research revealed a number of datasets that could be used to train an image classifier. The <b>FER2013+ dataset</b> looked to be the most promising with the largest number of datapoints. However, <b>accuracy</b> was a concern due to the noise in the labels, I attempted to mitigate using a pretrained cloud model to improve labeling.</li>
          </Appear>
          <Appear elementNum={3}>
            <li>In this research, I found face-api.js. A pretrained series of models running on <b>TensorFlow.js</b> - in the browser. This emerging technology would allow for an innovative approach of <b>distributed machine learning</b> - it could run across any device without installation.</li>
          </Appear>
        </ul>
      </SlideTemplate>
    </Slide>
    <Slide>
      <SlideTemplate 
        colour="#0583F2"
        title="Project Process"
        subtitle="inception"
        image={require("../assets/process.jpg")}>
        <ul>
          <Appear elementNum={0}>
            <Quote>
              <span>”...organizations that employ a well-defined process can develop complex systems in a repeatable and predictable way.”</span>
              <span>(Kruchten, 2004)</span>
            </Quote>
          </Appear>
          <Appear elementNum={1}>
            <li>I opted for an iterative development approach adapted from the Rational Unified Process (RUP) methodology.</li>
          </Appear>
          <Appear elementNum={2}>
            <li>RUP aims to rapidly <b>reduce risk</b> in a project through the initial <b>Inception</b> and <b>Elaboration</b>. To further mitigate risk, QA is necessary. Due to the short amount of time this consisted of manual test validation and strict code-review by Graham Taylor (AIE CTO).</li>
          </Appear>
          <Appear elementNum={3}>
            <li style={{marginBottom: "1.5em"}}>These key phases were mapped out in the project plan.</li>
            <img src={require("../assets/a4c-plan.png")} alt="" height="150px" style={{ margin: "0 auto",
            display: "block"
            }} />
            <p style={{
              fontSize: "0.6em"
            }}>References <br/><i>Kruchten, P. (2004). The rational unified process. Addison-Wesley.</i></p>
          </Appear>
        </ul>
      </SlideTemplate>
    </Slide>
    <Slide>
      <SlideTemplate 
        colour="#5207F2"
        title="Tech and Architecture"
        subtitle="elaboration"
        image={require("../assets/arch-v2-translucent.png")}>
        <ul>
          <Appear elementNum={0}>
            <li>As opposed to training a traditional classifier to run centrally, I opted to leverage Face-Api.js and TensorFlow.js to run the emotion detection model in a <b>distributed way</b>, in the browser.</li>
          </Appear>
          <Appear elementNum={1}>
            <li>This approach would allow me to <b>collect a dataset of emotional responses</b> to the imagery the application would use, all <b>via a browser survey</b>.</li>
          </Appear>
          <Appear elementNum={2}>
            <li>The main development effort would be in the React.js web-interface, styled via Styled Components (a CSS in JS framework). Building a web-app enables the <b>distributed training</b> mentioned prior and <b>ease of updates</b>, even whilst installed in-store.</li>
          </Appear>
          <Appear elementNum={3}>
            <li>The system is based on Google Cloud Platform, leveraging <b>Google AutoML Tables</b> to train a classifier on the collected dataset and <b>Firebase</b> for hosting, database, cloud compute (a prediction API proxy) and storage.</li>
          </Appear>
        </ul>
      </SlideTemplate>
    </Slide>
    <Slide>
      <SlideTemplate 
        colour="#0583F2"
        title="The solution"
        subtitle="construction and transition"
        image={require("../assets/a4c-screen.jpg")} imageChild={
          <div><ElfFrame perspective allow="camera" src="https://a4c-ai-gifting.web.app/experience" width="1080" height="1920" /></div>}>
        <ul>
          <Appear elementNum={0}>
            <li>The finished web-app and hardware were installed in the store a few days before the store opened.</li>
          </Appear>
          <Appear elementNum={1}>
            <li>The system underwent rigorous manual <b>stress-testing</b> and final <b>acceptance testing</b> with the Action for Children team whilst in situ. Further, edge cases such as internet connection failure were validated.</li>
          </Appear>
          <Appear elementNum={2}>
            <li>The system operated in the store from the 4th of December 2019 until the 18th of December, running continuously throughout store operation.</li>
          </Appear>
        </ul>
      </SlideTemplate>
    </Slide>
    <Slide>
        <ElfFrame allow="camera" src="https://a4c-ai-gifting.web.app/experience" width="1080" height="1920" />
    </Slide>
    <Slide>
      <YouTubeEmbed width="1007" height="566" src="https://www.youtube.com/embed/6HYv2hvvT_4?vq=hd720&modestbranding=1&showinfo=0&rel=0&cc_load_policy=1&color=white&disablekb=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></YouTubeEmbed>
    </Slide>
    <Slide backgroundImage={`url(${require("../assets/a4c-in-store-blue.jpg")})`} backgroundSize="cover">
      <QuoteSlide 
        quote="Over 500 visitors to the shop did the Elf.ai experience and remarkably and wonderfully, almost all of them then went on to support one of our vulnerable children as a result of that interaction."
        author="Julie Bentley, Action for Children CEO" />
    </Slide>
    <Slide>
      <SlideTemplate 
        colour="#5207F2"
        title="Results"
        subtitle="evaluation"
        image={require("../assets/stats.jpg")}>
        <ul>
          <Appear elementNum={0}>
            <li>The system ran continuously in-store for <b>2 weeks</b>, from the 4th December 2019 until the 18th December 2019. During this time, the experience was completed by customers over <b>550 times</b>.</li>
          </Appear>
          <Appear elementNum={1}>
            <li>Of those 550 customer sessions completing the Elf.ai gift recommendation experience, <b>93% went on to make a purchase</b>. Resulting in <b>£8,840</b> being raised for Action for Children.</li>
          </Appear>
          <Appear elementNum={2}>
            <li>Further, the system improved visibility of the store with at least <b>2,600 views</b> across Instagram and Snapchat.</li>
          </Appear>
          <Appear elementNum={3}>
            <li>However, these results do imply that the distribution of predictions was biased towards a single prediction, with 275 of the 500 predictions predicting a "safe place to sleep".</li>
          </Appear>
        </ul>
      </SlideTemplate>
    </Slide>
    <Slide>
      <SlideTemplate 
        colour="#0583F2"
        title="Recognition"
        subtitle="evaluation"
        image={require("../assets/coverage.jpg")}>
        <ul>
          <Appear elementNum={0}>
            <li>Since implementing the Elf.ai experience in store, it has been featured in numerous media outlets from Campaign to The Handbook, HELLO and Charity Digital.</li>
          </Appear>
          <Appear elementNum={1}>
            <li>Both Capgemini and Action for Children have promoted the store and the Elf.ai experience, with a series of case-studies on the external facing Capgemini website and a promotional case-study video.</li>
          </Appear>
          <Appear elementNum={2}>
            <li>Further, the project has been nominated for a <b>Better Society Award</b> in their 2020 awards under the category <b>Major Project of the Year</b>. The project has been shortlisted alongside other projects from companies such as Barclays.</li>
          </Appear>
        </ul>
      </SlideTemplate>
    </Slide>
    <Slide>
      <SlideTemplate 
        colour="#5207F2"
        title="Evaluation"
        subtitle="evaluation"
        image={require("../assets/store-exterior.jpg")}>
        <ul>
          <Appear elementNum={0}>
            <li>Overall, I feel that the project was highly successful in meeting the goals set at the outset.</li>
          </Appear>
          <Appear elementNum={1}>
            <li>For Action for Children, the statistics show a high conversion rate and clear donation influence, whilst promoting the store on social media. Capgemini has gained media exposure and a public-facing AI case-study.</li>
          </Appear>
          <Appear elementNum={2}>
            <li>However, a successful deliverable does not necessarily mean all aspects of a project were successful. Specifically, whilst the manual approach to QA and testing was sufficient on this project and necessary due to the timescales, I wouldn't recommend it for larger teams, as it requires a huge amount of individual discipline to maintain. The code-review process was highly effective though and I would continue to recommend this.</li>
          </Appear>
          <Appear elementNum={3}>
            <li>The predictions demonstrated a significant bias towards a single product type ("A safe place to sleep"). This is likely a result of an imbalance in the original training set, something that could be mitigated by class balancing. However, the 93% conversion rate maybe tells us this isn't a problem.</li>
          </Appear>
        </ul>
      </SlideTemplate>
    </Slide>
    <Slide>
      <SlideTemplate 
        colour="#0583F2"
        title="Future Recommendations"
        subtitle="evaluation"
        image={require("../assets/team.jpg")}>
        <ul>
          <Appear elementNum={0}>
            <li><b>Give yourself time.</b> Allocate as much time for automating QA as possible and try to stagger the design and development processes such that you aren't developing to a moving or incomplete specification. Impossible in this instance given the 5 week timescale.</li>
          </Appear>
          <Appear elementNum={1}>
            <li><b>Test, test, test.</b> User-test, manual-test, automated-test, acceptance-test. It's all valuable but most critically, make sure it's done regularly and in situ as early as possible. I found poor performance in the store's lighting for certain demographics initially, requiring configuration changes.</li>
          </Appear>
          <Appear elementNum={2}>
            <li><b>Delegate as much as you can.</b> Don't be afraid to use prebuilt services or models, there's likely a reason they're as popular as they are and when risk is the key cost, simplicity is key!</li>
          </Appear>
          <Appear elementNum={3}>
            <li><b>Research and backup.</b> When moving at this pace, things are certain to change, ensure your system is modular and that you've done your research so that you can quickly replace a sub-system with a simpler alternative. One backup is likely not enough, no harm in doubling up.</li>
          </Appear>
        </ul>
      </SlideTemplate>
    </Slide>
    <Slide>
      <VideoBackdrop 
        faded
        src="https://firebasestorage.googleapis.com/v0/b/a4c-ai-gifting.appspot.com/o/video%2Fmain-loop.webm?alt=media"
       />
       <TitleSlide title="Thank You" subtitle="Can I answer any questions?" />
    </Slide>
  </Deck>
);

export default Presentation
