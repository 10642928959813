import React from 'react';
import styled from 'styled-components';
const Content = styled.div`
  width: 65%;
  padding: 40px;
  margin: 0 auto;
  box-sizing: border-box;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  & p {
    font-size: 1.75em;
    text-align: center;
  }
  & h2 {
    margin-top: 0;
    font-size: 1.5em;
    margin: 0 auto;
    margin-right: 0;
    text-align: right;
    transform: translate(15%);
  }
`;

export default ({ quote, author }) => {
  return <Content>
    <p>"{quote}"</p>
    <h2>{author}</h2>
  </Content>
}