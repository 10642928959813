import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  color: ${props => props.black ? "black" : "white"};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background: ${props => props.colour || "transparent"};
  flex-direction: ${props => props.flip ? "row-reverse" : "row"};
`;

const Image = styled.div`
  background-image: url("${props => props.image}");
  background-size: cover;
  background-position: center center;
  display: block;
  height: 100%;
  width: 45%;
  overflow: hidden;
`;

const Content = styled.div`
  width: 55%;
  padding: 40px;
  box-sizing: border-box;
  & h3 {
    font-size: 1em;
    text-transform: uppercase;
    font-weight: 300;
    margin: 0;
  }
  & h2 {
    margin-top: 0;
    font-size: 2.2em;
  }
  & ul {
    padding-left: 0;
    list-style: none;
    max-width: 550px;
  }
  & ul li {
    margin-bottom: 3em;

  }
`;

export default ({ black, colour, title, subtitle, children, image, imageChild, flip }) => {
  return <Container flip={flip} colour={colour} black={black}>
    <Content>
      <h3>{subtitle}</h3>
      <h2>{title}</h2>
      {children}
    </Content>
    <Image image={image}>
      {imageChild}
    </Image>
  </Container>
}