import React from 'react';
import styled from 'styled-components'

const Slide = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  & h1 {
    font-size: 8em;
    margin: 0;
  }
  & h2 {
    font-size: 2em;
    font-weight: 300;
    margin: 0;
  }
`;

export default ({ title, subtitle }) => <Slide>
  <h1>{title}</h1>
  <h2>{subtitle}</h2>
</Slide>